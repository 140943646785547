import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useStyles } from "../../styling/global/global";
import TextField from "@material-ui/core/TextField";
import { Divider } from "@material-ui/core";

import { AppStore } from "../../../stores/AppStore";

interface MenuModalProps {
  appStore?: AppStore;
  stream: boolean;
  mute: boolean;
  handleStream: (sensor_id_str: string) => void;
  handleMute: (
    minutes: number,
    site_id: number,
    sensor_id: number,
    alert_channel_id: number,
  ) => void;
  handleStats: (
    sensor_id_str: string,
    sensor_id: number,
    site_id: number,
  ) => void;
  muted: boolean;
  stats: boolean;
  sensor_id_str: string;
  site_id: number;
  sensor_id: number;
  alert_channel_id: number;
  theme: string;
  open: boolean;
  onClose: () => void;
  name: string;
  stats_site_id: number;
}
const MenuModal: React.FC<MenuModalProps> = ({
  stats_site_id,
  name,
  onClose,
  open,
  stream,
  handleStream,
  mute,
  handleMute,
  muted,
  handleStats,
  stats,
  sensor_id_str,
  site_id,
  sensor_id,
  alert_channel_id,
  theme,
  appStore,
}: MenuModalProps) => {
  const [muteMinutes, setMinutes] = useState<number | undefined>(5);
  const [muteHours, setHours] = useState<number | undefined>(0);
  const [errorModalOpen, setErrorModalOpen] = useState(false);

  const classes = useStyles();
  const handleClose = () => {
    onClose();
  };

  const Stream = () => {
    handleClose();
    handleStream(sensor_id_str);
  };

  const Mute = (
    minutes: number,
    site_id: number,
    sensor_id: number,
    alert_channel_id: number,
  ) => {
    handleClose();
    handleMute(minutes, site_id, sensor_id, alert_channel_id);
  };

  const UnMute = (
    site_id: number,
    sensor_id: number,
    alert_channel_id: number,
  ) => {
    handleClose();
    handleMute(-1, site_id, sensor_id, alert_channel_id);
  };

  const Stats = () => {
    handleClose();
    handleStats(sensor_id_str, sensor_id, stats_site_id);
  };

  const hoursHandler = (e: any) => {
    if (!isNaN(+e.target.value)) {
      console.log(" is not a number");
      return;
    }
  };

  const handleErrorModalClose = () => {
    setErrorModalOpen(false);
  };

  const customMute = () => {
    const muteTime = muteMinutes! + muteHours! * 60;

    if (roles.includes("web_mute_one_hour") && muteTime > 60) {
      setErrorModalOpen(true);
      return;
    }

    console.log(
      `Minutes: ${muteMinutes} - Hours: ${muteHours}  total:${muteTime}`,
    );
    Mute(muteTime!, site_id, sensor_id, alert_channel_id);
  };

  let channel_roles = appStore?.authService.getChannelRoles();
  let roles: string[] = [];

  if (channel_roles && Array.isArray(channel_roles)) {
    roles = channel_roles;
  }

  var web_mute_controls = false;
  if (
    roles.includes("web_mute_controls") ||
    roles.includes("web_mute_one_hour")
  ) {
    web_mute_controls = true;
  }

  return (
    <Dialog
      PaperProps={{
        style: {
          borderRadius: 20,
        },
      }}
      onClose={handleClose}
      open={open}
    >
      <Dialog
        open={errorModalOpen}
        onClose={handleErrorModalClose}
        PaperProps={{
          style: {
            borderRadius: 10,
            padding: "20px",
            textAlign: "center",
          },
        }}
      >
        <div style={{ padding: "10px", fontSize: "1rem", color: "#333" }}>
          You can mute for no more than an hour.
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            onClick={handleErrorModalClose}
            variant="contained"
            style={{
              marginTop: "20px",
              backgroundColor: "#15325F",
              color: "#fff",
              width: "120px",
              padding: "8px 16px",
              borderRadius: "20px",
            }}
          >
            OK
          </Button>
        </div>
      </Dialog>

      <DialogTitle className={classes.bold_text}>{name}</DialogTitle>
      <div className={classes.contollers}>
        {stats ? ( // stats
          <Button className={classes.menu_button} onClick={Stats}>
            <span className={classes.bold_text}>Show Stats</span>
          </Button>
        ) : undefined}
        {stats ? <div className={classes.space_smallest}></div> : undefined}{" "}
        {/* stats */}
        {stream ? ( // stream
          <Button className={classes.menu_button} onClick={Stream}>
            <span className={classes.bold_text}>Live Stream</span>
          </Button>
        ) : undefined}
        {stream ? <div className={classes.space_smallest}></div> : undefined}{" "}
        {/* stream */}
        {mute && muted ? (
          <Button
            className={classes.menu_button}
            onClick={() => UnMute(site_id, sensor_id, alert_channel_id)}
          >
            <span className={classes.bold_text}>Unmute</span>
          </Button>
        ) : undefined}
        {mute && muted ? (
          <div className={classes.space_smallest}></div>
        ) : undefined}
      </div>
      <div className={classes.menu_container}>
        {!muted ? (
          web_mute_controls ? (
            <>
              <div style={{ color: "#15325F" }} className={classes.bold_text}>
                SET MUTE TIME
              </div>

              <>
                <div className={classes.muteInputs}>
                  <input
                    className={classes.muteInput}
                    type="number"
                    value={muteHours}
                    onChange={(e) => {
                      setHours(Math.floor(Math.max(0, +e.target.value)));
                    }}
                  />
                  <span className={classes.muteInput}>HOURS &nbsp;</span>
                  <input
                    className={classes.muteInput}
                    type="number"
                    value={muteMinutes}
                    onChange={(e) => {
                      setMinutes(Math.floor(Math.max(0, +e.target.value)));
                    }}
                  />
                  <span className={classes.muteInput}>MINUTES</span>
                </div>
              </>
            </>
          ) : null
        ) : null}
        <div className={classes.button_container_small}>
          <Button
            variant="contained"
            className={classes.cancel_button}
            onClick={handleClose}
          >
            CANCEL
          </Button>

          {!muted ? (
            web_mute_controls ? (
              <Button
                variant="contained"
                className={classes.mute_button}
                onClick={() => {
                  customMute();
                }}
              >
                MUTE
              </Button>
            ) : null
          ) : undefined}
        </div>
      </div>
    </Dialog>
  );
};

export default MenuModal;
